.viewBtn{
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 15px;
    margin-left: 10px;
}

.edit-icon{
    font-size: 20px;
    cursor: pointer;
    /* color: rgb(150, 107, 235); */
}
.view-icon{
    font-size: 20px;
    cursor: pointer;
    /* color: rgb(245, 42, 248); */
}
.actions{
    display: flex;
    justify-content:space-evenly;
}

.collection-badge{
    margin-bottom: 10px;
    background-color: var(--primary-color__purple);
}

.collection-table{
    margin-top: 5px;
}

.control-buttons{
    display: flex;
    justify-content: flex-end;
    /* justify-content: space-evenly; */
}