.dashboard-div{
  width:100%;
    margin-left: 19rem;
    margin-top: 3.5rem;
    transition: all 0.4s ease;
    padding-bottom: 1rem;
    padding-right: 20rem;
}

.Auth-form-container {

  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.dashboard-div-active{
   width:100%;
    margin-left: 6.5rem;
    padding-right: 8rem;
    margin-top: 3.5rem;
    transition: all 0.4s ease;
    padding-bottom: 1rem;
}