
.table-div{
    margin-top: 10px;
}

.table-title{
    font-size: 2rem;
    font-weight: 500;
}

.view-btn{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
}

td{
    font-size: 13px;
}

th{
    font-size: 13px;
}