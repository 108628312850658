.export-btn{
    background-image: linear-gradient(
        to right,
        var(--primary-color__blue),
        var(--primary-color__purple)
      );
      border-color: var(--primary-color__purple);
      height: 2rem;
      font-size: small;
      color: white;
      margin: 3px;
}

.small-text{
    font-size: 12px;
} 
.delete-icon{
  cursor: pointer;
  font-size: 20px;
  color: rgb(223, 94, 94);
  font-weight: 400;
}

.export-icon{
    margin-bottom: 4px;
    margin-right:4px ;
}

.export-class{
    display: flex;
    justify-content: flex-end;
}