.new-icon{
    font-size: 1.5rem;
    cursor: pointer;
    margin: 10px;
    margin-bottom:30px ;
}

.back-button{
    font-size:1.5rem;
    cursor: pointer;
    margin-bottom: 10px;
  }



  .searchBar{
    width:30rem;
    height: 30px;

  }
  .primary-btn{
    background-image: linear-gradient(
      to right,
      var(--primary-color__blue),
      var(--primary-color__purple)
    );
    border-color: var(--primary-color__purple);
    margin: 2px;
  }

  .primary-btn:hover{
    background-color: var(--primary-color__purple);
    border-color: var(--primary-color__purple);
  }

  .primary-btn:disabled{
    background-color: var(--primary-color__purple);
    border-color: var(--primary-color__purple);
  }

  .primary-btn:focus{
    background-color: var(--primary-color__purple);
    border-color: var(--primary-color__purple);
  }

  .details-header{
    font-size: medium;
    font-weight: bold;
    margin-bottom: 5px;
    color: #212529;
  }