.sidebar-color {
    color: white;
    background-color: rgb(146, 94, 176);
}

.sidebar-content{
    background-image: linear-gradient(
        to right,
        var(--primary-color__blue),
        var(--primary-color__purple)
      );
}




.sidebar-header{
    height: 3rem;
    background-color: white;
}


.sidebar-div {
    display: flex;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    z-index: 9999;
}

.navbar-div{
    background-color: white;
    border-radius: 30px;
}
.active{
    background-color: rgb(255, 255, 255);
    /* border-radius: 10px; */
    height: 40px;
}

.active-submenu{
    background-color: rgb(255, 255, 255);
    height: 40px;
    font-size: small;
    margin-top: 5px;
    

}
.active-text{
    color: rgb(129, 0, 112);
    font-weight: 900;
}

.down-pointer{
    margin-left: 2rem;
}

.manage-submenu{
    margin-left: 1rem;
}
.item-content{
    font-size: 13px;
}


