.success{
    font-size: 1.5rem;
    color: var( --primary-color__purple);
    font-weight: 500;
    padding-right: 20rem;
}



.submit_btn{
    padding: 5px;
    margin-right: 20px;
}

.form_container{
    /* Method 1: */
    width: 58rem;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
 .page-header{
    font-size:2rem;
    font-weight: bold;

 } 
 