
.card{
    width: 18rem;
    border-radius: 1px;
    background: linear-gradient(45deg, #ffffff, #ffffff);
    box-shadow: 2px 2px 2px 1px rgba(146, 145, 145, 0.2);
    margin-bottom: 1rem;
    size: 100px;
}
.card-col{
    padding-top:2rem;
    position: relative;
  
}

.react-icon{
    font-size:25px;
}

.icon-spam{
   margin-right: 10px;
}

.dashboard-title{
    font-size:1.8rem;
    font-weight: 800;
    padding: 0.5rem;
    color: black;
}

.small-spinner{
    display: flex;
    transition: all 0.4s ease;
    justify-content: flex-end;
    height: 0px;
    padding-top: 5px;
    padding-right: 10px;
}

.card-row{
    /* padding:1rem; */
    margin-top: 1.5rem;
}

.card-title h2{
    font-size: 20px;
}

.card-btn{
   width:240px;
   height: 30px;
   font-size: small;
   background-image: linear-gradient(
    to right,
    var(--primary-color__blue),
    var(--primary-color__purple)
  );
}

.setting-icon{
    margin-right: 3px;
}

.page-item.active .page-link{
    background-color:var(--primary-color__purple);
    border-color: var(--primary-color__purple);
}

#pageDropDown{
    background-color: var(--primary-color__purple);
}