.required-icon {
  font-weight: 800;
  font-size:15px;
  color:red;
}


.cancel-btn{
  margin-left: 10px;
}

#replace-btn{
  display: flex;
  margin-right: auto;
}
/* input[type=file]{
  width:100%;
  border:2px solid #aaa;
  border-radius:4px;
  margin:8px 0;
  outline:none;
  padding:8px;
  box-sizing:border-box;
  transition:.3s;
}

input[type=file]:focus{
  border-color:dodgerBlue;
  box-shadow:0 0 8px 0 dodgerBlue;
} */