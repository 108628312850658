:root {
  --primary-color__blue: #42428b;
  --primary-color__purple: #925eb0;
  --body-color__gray: #7b7778;
  --body-color__yellow: yellow;
}

body {
  color: var(--body-color__gray);
  background-color:rgb(255, 255, 255);
}

.divider-line {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.divider-line span {
  display: inline-block;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  padding: 4px;
}

.divider-line hr {
  width: 100%;
}

.signIn__btn--options {
  margin-top: 1rem;
}


.update-comp {
  width: 35%;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  border: 1px solid var(--primary-color__purple);
}

.input:focus-visible {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  border: 1px solid var(--primary-color__purple);
  outline: transparent;
  box-shadow: none;
}

.aLink_color--yellow {
  color: #988b5e;
}

.signIn-link {
  font-weight: bold;
}

.form-title {
  color: #7b7778;
}

.aLink_color--purple {
  color: var(--primary-color__purple);
}

.aLink_color--yellow {
  color: var(--body-color__yellow) !important
}

.nav_container ul {
  padding-left: 0;
}

.nav_container ul li {
  list-style: none;
  display: inline-block;
  padding-right: 1rem;
}


.custom-height-100 {
  height: 100%;
  min-height: 100vh;
}

.main-center {
  max-width: 400px;
}

.card-btn{
  background-color: var(--primary-color__purple);
  border-color: var(--primary-color__purple);
}

.card-btn:hover{
  background-color: var(--primary-color__purple);
  border-color: var(--primary-color__purple);
}