
.navabar-text {
    font-size: medium;
}

.navbar{
    background-image: linear-gradient(
        to left,
        var(--primary-color__blue) ,
       white 75%
      );
position:fixed;
height: 3rem;
z-index: 100;
}

.menu-icon{
    font-size: x-large;
    display: flex;
    margin-left: 2rem;
    color: rgb(0, 0, 0);
    cursor: pointer;
}

.logout-btn{
    font-weight: 700;
    cursor: pointer;
    font-size: larger;
    color: white;
}
.logout-logo{
    font-size:1.5rem;
    margin-right: 5px;
}
@media only screen and (max-width: 600px) {
    .logo {
        height: 30px !important;
      }
}

.logo {
  height: 25px;
  margin-left: -1rem;
  margin-top: -1rem;
}
