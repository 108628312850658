@media only screen and (max-width: 600px) {
  .leftCol_div {
    /* background-color: rgb(0, 191, 255) !important; */
    visibility: collapse !important;
  }
  .body-container {
    background-color: white !important;
  }
}

body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  /* overflow: hidden; */
}

* {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}


.icon-size {
  font-size: 22px
}
/* html {height: 100%} */

.wrapper {
  min-height: 100vh;
  display: flex;
  /* Direction of the items, can be row or column */
  flex-direction: column;
}


.mouse-cursor {
  cursor: pointer
}



.forgot-me__link{
  width: 100%;
  display: flex;
  top: -1opx;
  padding: 0 !important;
  margin: 0 !important;
  justify-content: end;
}
