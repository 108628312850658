.modle-content{
    font-size: 13px;
}

.fotter-modal{
    display: flex;
    /* justify-content: space-between; */
}

.tick-icon{
    color: rgb(139, 234, 139);
    font-size: 40px;
}

.error-icon{
    color: red;
    font-size: 40px;
}