.spinner{
    position: fixed;
    left: 50%;
    top: 50%;
    font-weight: 200;
    font-size:x-large;
    width: 60px;
    height: 60px;
    transition: all 0.4s ease;
    z-index: 100000;
}

.small-spinner{
    color: var(--primary-color__purple);
}

